import React, { useEffect, useRef, useState } from 'react';

const Projects: React.FC = () => {
  const [isPaused, setIsPaused] = useState(false);
  const trackRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const animationRef = useRef<Animation | null>(null);

  const projectCards = [
    { title: 'Solana Foundation', image: '/solana-found.png', description: 'Grant for work in AI integration in DApps', link: 'https://x.com/SolanaFndn'},
    { title: 'LowLifeForms', image: '/lowlife-forms.png', description: 'IP, Blockchain Gaming, Community', link: 'https://x.com/LowLifeForms'},
    { title: 'ABC Runes', image: '/abc-runes.png', description: 'Analytics & Marketplace platform for Runes', link: 'https://x.com/ABCRunes' },
    { title: 'Cardboard Citizens', image: '/cz.png', description: 'IP, pNFT, Community', link: 'https://x.com/cardboard_z' },
    { title: 'Solana Cash', image: '/solana-cash.png', description: 'Based on Epoch 640. SPL Token', link: 'https://x.com/SolanaCashFdn'},
    { title: 'Spiderswap', description: 'Solana Aggregation & Infrastructure Engine', link: 'https://x.com/spiderswap' },
    { title: 'The Keepers', image: '/the-keepers.png', description: 'IP, Community, AI PFP Staking Provider', link: 'https://x.com/TheKeepersNFT' },
    { title: 'Nebula', image: '/nebula.png', description: 'Discord Server Auditing and setup service', link: 'https://x.com/nebulaguardio' },
    { title: 'Bit Freaks', image: '/bit-freaks.png', description: 'Blockchain Gaming Engine - Web & Mobile', link: 'https://x.com/BitFreaksNFT' },
  ];

  // Duplicamos las tarjetas para crear el efecto infinito
  const allCards = [...projectCards, ...projectCards];

  useEffect(() => {
    if (!trackRef.current) return;
    
    const keyframes = new KeyframeEffect(
      trackRef.current,
      [
        { transform: 'translateX(0)' },
        { transform: `translateX(${-projectCards.length * 280}px)` }
      ],
      {
        duration: 30000,
        iterations: Infinity,
        easing: 'linear',
      }
    );

    animationRef.current = new Animation(keyframes, document.timeline);
    animationRef.current.play();

    return () => {
      if (animationRef.current) {
        animationRef.current.cancel();
      }
    };
  }, [projectCards.length]);

  const handleMouseEnter = () => {
    if (animationRef.current) {
      animationRef.current.pause();
      setIsPaused(true);
    }
  };

  const handleMouseLeave = () => {
    if (animationRef.current) {
      animationRef.current.play();
      setIsPaused(false);
    }
  };

  return (
    <section className="projects-section" style={{ backgroundImage: `url('/minimalistic-black.svg')` }}>
      <h1 className="projects-title-1" ref={titleRef}>
        SOLUTIONS AND CONTRIBUTION
      </h1>
      
      <div className="cards-wrapper-projects fade-slide">
        <div className="cardsBox">
          <div 
            className="cardsTrack"
            ref={trackRef}
          >
            {allCards.map((card, index) => (
              <figure 
                className={`cardContent ${isPaused ? 'paused' : ''}
                  ${card.title === 'Cardboard Citizens' && 'noLeft small'}
                  ${card.title === 'Solana Cash' && 'noLeft'}
                  ${card.title === 'The Keepers' && 'noLeft small'}
                  ${card.title === 'Nebula' && 'noLeft'}
                 `}
                key={`${card.title}-${index}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {card.image ? 
                  <img 
                    src={card.image} alt={card.title} 
                    className={`cardContent-img 
                        ${card.title === 'The Keepers' &&'h-36'}
                        ${card.title === 'Cardboard Citizens' && 'h-36'}
                        ${card.title === 'Solana Cash' && 'h-60'}
                        `} /> 
                  : <h4>{card.title}</h4>
                }
              </figure>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
